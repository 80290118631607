const RedHeartSvg = () => {
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="30.000000pt" height="30.000000pt" viewBox="0 0 1280.000000 1248.000000"
             preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1248.000000) scale(0.100000,-0.100000)"
            fill="#f44336" stroke="none">
                <path d="M2860 11344 c-19 -2 -80 -9 -135 -15 -680 -71 -1336 -412 -1794 -933
                -381 -434 -661 -983 -826 -1621 -342 -1324 170 -2767 1549 -4365 342 -396 875
                -927 1361 -1356 705 -622 1556 -1266 2365 -1790 218 -142 414 -264 423 -264
                20 0 519 323 841 544 1779 1224 3216 2581 4038 3816 533 800 837 1566 908
                2288 14 149 15 491 0 622 -27 257 -88 526 -181 804 -192 575 -457 1033 -808
                1398 l-114 119 34 37 c31 34 31 35 4 12 -16 -14 -33 -29 -37 -34 -3 -5 -49 27
                -102 71 -52 45 -105 88 -116 96 -18 12 -19 18 -10 42 6 15 8 30 6 33 -3 2 -8
                -7 -12 -22 -3 -14 -9 -26 -12 -26 -3 0 -37 22 -76 49 -379 260 -774 410 -1256
                478 -173 24 -583 24 -765 0 -515 -69 -982 -241 -1390 -513 -431 -287 -749
                -666 -915 -1090 -22 -57 -41 -93 -44 -84 -46 125 -64 169 -112 264 -244 487
                -713 907 -1304 1169 -282 124 -583 208 -910 252 -109 15 -532 28 -610 19z"/>
                <path d="M10728 10363 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
            </g>
        </svg>
    );
}

export default RedHeartSvg;